/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import TwitterIcon from '@material-ui/icons/Twitter'
import GitHubIcon from '@material-ui/icons/GitHub'

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-icon.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        marginBottom: rhythm(2.5),
      }}
    >
      <div
        style={{
          fontFamily: `"Roboto", "serif"`,
          fontSize: `1.5rem`,
          fontWeight: 900,
        }}
      >
        プロフィール
      </div>
      <div
        style={{
          display: `flex`,
        }}
      >
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={author.name}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            minWidth: 50,
            borderRadius: `100%`,
          }}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
        <p>
          koyamaaa2です。
          <div
            style={{
              display: `flex`,
              padding: `1rem 0`,
            }}
          >
            <div>
              <a
                href="https://github.com/koyamaaaa" target="_blank" rel="noopener noreferrer"
                style={{
                  boxShadow: `none`,
                  color: `#24292f`,
                }}
              >
                <GitHubIcon/>
              </a>
            </div>
          </div>
        </p>
      </div>
    </div>
  )
}

export default Bio
