import React from "react"
import { Link } from "gatsby"

import Bio from "../components/bio"
import { rhythm, scale } from "../utils/typography"

import "./typeface-font.css"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  let main

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
          textAlign: `center`
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
    main = (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginBottom: `1.25rem`,
        }}
      >
        {children}
      </div>
    )
  } else {
    header = (
      <div
        style={{
          ...scale(1.5),
          fontFamily: `"Montserrat","sans-serif"`,
          fontWeight: 900,
          marginBottom: rhythm(1.5),
          marginTop: 0,
          textAlign: `center`
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </div>
    )
    main = (
      <div>
        {children}
      </div>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(30),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      {main}
      <Bio />
      <div
        style={{
          textAlign: `center`,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/privacy-policy`}
        >
          プライバシーポリシー
        </Link>
      </div>
      <footer
        style={{
          textAlign: `center`,
        }}
      >
        © {new Date().getFullYear()}&nbsp;
        <Link to={`/`}>
          follmy.com
        </Link>
      </footer>
    </div>
  )
}

export default Layout
